import styled, { css } from 'styled-components'
import { Wrapper, Button } from '@renderbus/common/components'
import { Media, typography, spacing, color, hideSMDown, hideSMUp } from '@renderbus/common/theme'
import GoldIcon from '../images/year-end-recharge/2023/gold-icon.png'
import Gift01Icon from '../images/year-end-recharge/2023/gift01.png'
import Gift02Icon from '../images/year-end-recharge/2023/gift02.png'
import RightsIcon from '../images/year-end-recharge/2023/rights.png'
import CouponIcon from '../images/year-end-recharge/2023/coupon.png'
import aiMbImg from '../images/year-end-recharge/2023/ai-mb.png'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: #0c1f18;
  border-radius: 12px;
  padding: 76px 0;
  justify-content: space-evenly;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`
export const LotteryContentContainer = styled(ContentContainer)`
  flex-direction: row;
  margin-bottom: 100px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
  }
`

export const LotterySubTitle = styled.div`
  font-size: ${typography.text};
  color: #c8c8c8;
  line-height: 22px;
  ${Media.lessThan(Media.small)} {
    font-size: 8px;
    line-height: 10px;
  }
`
export const TitleContent = styled.div`
  padding: 104px 0 62px;
  display: block;
  margin: 0 auto;
  position: relative;
  font-weight: bold;
  color: #12cc75;
  line-height: 27px;
  text-align: center;
  background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.title};
  line-height: 1;
  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: ${`url(${GoldIcon})`};
    background-size: 40px 40px;
  }
  ::before {
    left: ${item => item.iconSpacing || '40%'};
  }
  ::after {
    right: ${item => item.iconSpacing || '40%'};
    transform: rotate(-90deg);
  }
  ${Media.lessThan(Media.small)} {
    padding: 40px 0 32px;
    padding-top: ${p => p.mdPaddingTop};
    line-height: ${p => (p.mdPaddingTop ? '14px' : '20px')};
    font-size: 21px;
    ::before,
    ::after {
      width: 23px;
      height: 23px;
      background-size: 23px 23px;
      top: ${p => p.mdPaddingTop && '8px'};
    }
    ::before {
      left: ${p =>
        p.iconMbSpacing ? 'calc((100% - 160px - 2rem) / 2)' : 'calc((100% - 122px - 2rem) / 2)'};
    }
    ::after {
      right: ${p =>
        p.iconMbSpacing ? 'calc((100% - 160px - 2rem) / 2)' : 'calc((100% - 122px - 2rem) / 2)'};
    }
  }
`
export const PicWrapper = styled(Wrapper)`
  padding-top: 120px;
  ${Media.lessThan(Media.small)} {
    padding-top: 0;
  }
`

export const PicTitleContent = styled.div`
  height: 68px;
  background: linear-gradient(180deg, #338066 0%, #0f3327 100%);
  border-radius: 34px;
  font-size: 30px;
  line-height: 27px;
  color: #ffe6b2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.pcWidth};
  margin: 120px auto 0;
  margin-top: ${p => p.marginTop};
  ::before {
    content: attr(data-title);
    position: absolute;
    background-image: ${`url(${Gift01Icon})`};
    background-size: 134px 132px;
    background-repeat: no-repeat;
    height: 132px;
    width: 134px;
    top: -64px;
    color: #1f9970;
    left: -30px;
    padding-top: 80px;
    padding-left: 16px;
    font-weight: bold;
  }
  ::after {
    content: '';
    position: absolute;
    background-image: ${`url(${Gift02Icon})`};
    background-size: 102px 90px;
    background-repeat: no-repeat;
    height: 90px;
    width: 102px;
    right: 0;
    top: -22px;
  }
  .pic-title-detail {
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    background: transparent;
    font-size: 18px;
    line-height: 24px;
    margin: 40px auto 30px;
    width: auto;
    max-width: 330px;
    margin-top: ${p => p.marginTop};
    ::before {
      background-size: 60px 64px;
      height: 64px;
      width: 60px;
      top: 0;
      left: ${p => p.mbLeft || '0'};
      font-size: 14px;
      padding-left: 6px;
      padding-top: 34px;
    }
    ::after {
      display: none;
    }
    .pic-title-detail {
      display: block;
      padding-left: 64px;
      line-height: 28px;
      padding-top: 10px;
      span {
        line-height: 24px;
      }
    }
    .pic-title-detail02 {
      display: none;
    }
  }
`

export const RightsDetail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .rights-title {
    font-size: ${typography.h4};
    color: #ffffff;
    line-height: 30px;
    padding-bottom: 10px;
  }
  .right-content {
    display: flex;
    margin-bottom: 0;
  }
  .member-content {
    font-size: 20px;
    color: #ffffff;
    line-height: 30px;
    background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    padding-left: 40px;
    padding-right: 30px;
    ::before {
      content: '';
      position: absolute;
      background-image: ${`url(${RightsIcon})`};
      background-size: 25px 21px;
      background-repeat: no-repeat;
      height: 21px;
      width: 25px;
      top: 6px;
      left: 10px;
    }
  }
  .highlight {
    font-weight: bold;
    padding-left: 2px;
  }
  ${Media.lessThan(Media.small)} {
    .rights-title {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 14px;
      padding-top: 15px;
    }
    .member-content {
      font-size: 11px;
      line-height: 18px;
      padding-left: 16px;
      padding-right: 4px;
      ::before {
        background-size: 14px 12px;
        height: 12px;
        width: 14px;
        top: 3px;
        left: 0;
      }
    }
  }
`

export const HighltTitle = styled.span`
  font-size: ${p => p.fontSize || '36px'};
  color: #ffe6b2;
  line-height: 38px;
  font-weight: bold;
  background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${Media.lessThan(Media.small)} {
    font-size: ${p => p.mbFontSize || '21px'};
  }
`

export const StepImgWrap = styled.div`
  width: 1095px;
  text-align: center;
  padding: 20px 0 6px;
  img {
    padding-bottom: 80px;
    &:nth-child(2) {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 0 20px 30px;
    img:first-child {
      display: none;
    }
    img:nth-child(2) {
      width: 232px;
      display: block;
      margin: 40px auto 30px;
      padding-bottom: 0;
    }
  }
`
export const StepPicImgWrap = styled(StepImgWrap)`
  width: 100%;
  ${Media.lessThan(Media.small)} {
    padding: 0 0 30px;
    img:nth-child(2) {
      width: 290px;
      padding: 0;
      margin: 30px auto 40px;
    }
  }
`

export const LotteryContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  border-radius: 12px;
  height: 240px;
  align-items: center;
  border: 0;
  background: linear-gradient(45deg, #1b593a 0%, rgba(15, 51, 39, 0.3) 100%);
  .lottery-title {
    font-size: ${typography.h4};
    color: #ffffff;
    line-height: 32px;
  }
  .lottery-img {
    display: block;
    margin: 34px auto;
    background-image: ${item => `url(${item.urlSrc})`};
    background-position: center;
    background-repeat: no-repeat;
    height: 68px;
    width: 100%;
  }
  :hover {
    background: linear-gradient(45deg, #1b593a 0%, #0f3327 100%);
    .lottery-title {
      color: #ffe6b2;
    }
    .lottery-img {
      background-image: ${item => `url(${item.hoverUrlSrc})`};
    }
  }
  ${Media.lessThan(Media.small)} {
    height: 120px;
    width: 100px;
    margin: ${spacing.large} 0;
    border-width: 1px;
    .lottery-title {
      font-size: 9px;
      line-height: 16px;
      padding-bottom: 4px;
    }
    .lottery-img {
      height: 34px;
      margin: 18px auto;
      background-size: auto 34px;
    }
  }
`

export const JoinActivityButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 70px;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 0 auto;
  border-radius: 30px;
  max-width: 484px;
  width: ${p => p.width};
  position: relative;
  &:hover {
    box-shadow: 0px 6px 11px 1px rgba(102, 204, 136, 0.3);
    img {
      animation: none;
    }
  }
  @keyframes zoomIn {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  img {
    position: absolute;
    top: 40px;
    right: -60px;
    animation: zoomIn 1s infinite;
  }
  ${Media.lessThan(Media.small)} {
    max-width: 260px;
    height: 35px;
    font-size: ${typography.textSmall};
    line-height: 1;
    margin: 10px auto 30px;
    margin-top: ${p => p.marginTop};
    padding: 10px 38px;
    width: ${p => p.mbWidth};
    img {
      height: 40px;
      top: 22px;
      right: -30px;
    }
  }
`
export const PicRechargeReceiptTip = styled.div`
  font-size: 20px;
  color: #14664b;
  line-height: 20px;
  width: 150px;
  height: 40px;
  background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 100%);
  border-radius: 19px;
  position: absolute;
  top: -50px;
  left: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  ::after {
    content: '';
    width: 0;
    height: 0;
    border: 20px solid;
    position: absolute;
    border-top: 0;
    top: 38px;
    left: 56px;
    transform: rotate(180deg);
    border-color: transparent transparent #ffe6b2;
  }
  ${Media.lessThan(Media.small)} {
    width: 90px;
    height: 32px;
    font-size: 12px;
    left: 114px;
    top: -37px;
    ::after {
      top: 22px;
      left: 30px;
      border-radius: 12px;
    }
  }
`

export const RechargeInputContainer = styled.div`
  display: flex;
  font-weight: bold;
  color: #ffffff;
  height: 60px;
  align-items: center;
  font-size: 24px;
  margin-top: 84px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    height: 35px;
    font-size: 12px;
    margin-top: 70px;
  }
`
export const RechargeInput = styled.input`
  width: 240px;
  height: 60px;
  border: 1px solid rgb(255, 255, 255, 0.4);
  border-right: 0;
  border-left: 0;
  background: linear-gradient(0deg, rgb(11, 38, 29, 0.4) 0%, rgb(23, 77, 59, 0.4) 100%);
  padding-left: 0;
  font-weight: 400;
  border-radius: 0;
  :focus {
    outline: none;
  }
  ${Media.lessThan(Media.small)} {
    width: 126px;
    height: 35px;
  }
`
export const RechargePrefix = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid rgb(255, 255, 255, 0.4);
  background: linear-gradient(0deg, rgb(11, 38, 29, 0.4) 0%, rgb(23, 77, 59, 0.4) 100%);
  border-right: 0;
  border-radius: 30px 0px 0px 30px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    width: 24px;
    height: 35px;
    margin-left: 8px;
  }
`

export const RechargeButton = styled(JoinActivityButton)`
  width: 180px;
  height: 60px;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  border-radius: 0px 30px 30px 0px;
  padding: 10px 30px;
  cursor: pointer;
  ${p =>
    p.isDisableRecharge &&
    css`
      cursor: no-drop;
      background: #999999;
      color: #ffffff;
      pointer-events: none;
      box-shadow: 0px 10px 16px 4px rgba(20, 102, 75, 0.3);
    `}
  ${Media.lessThan(Media.small)} {
    width: 110px;
    height: 35px;
    margin: 0;
    padding: 0;
  }
`
export const RechargeErrorTip = styled.div`
  height: 18px;
  font-size: 16px;
  color: #ff9933;
  margin: 20px auto 0;
  ${Media.lessThan(Media.small)} {
    font-size: 10px;
    margin-top: 10px;
    height: 10px;
  }
`

export const YearEndRechargeBg = styled.div`
  background: linear-gradient(177.95deg, #080826 0%, #0d1a15 40%);
  padding-bottom: ${p => (p.isPicture ? '160px' : '214px')};
  margin-top: -234px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  ${Media.lessThan(Media.small)} {
    background: #0d1a15;
    padding-bottom: 50px;
    margin-top: -130px;
  }
`

export const RechargeTips = styled.div`
  color: #ffffff;
  font-size: 16px;
  height: 48px;
  background: linear-gradient(180deg, #338066 0%, #0f3327 100%);
  border-radius: 24px;
  padding: 10px 30px;
  margin-top: 28px;
  ${Media.lessThan(Media.small)} {
    font-size: 10px;
    height: 27px;
    border-radius: 14px;
    padding: 7px 23px;
    margin-bottom: 40px;
    margin-top: 15px;
  }
`

export const PrizeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px 10px 32px;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0 0;
  }
`
const picRechargeItemActive = (hoverSrc, disableHover) => {
  return css`
    img {
      content: ${hoverSrc ? `url(${hoverSrc})` : ''};
    }
    background: ${disableHover
      ? 'transparent'
      : !hoverSrc && 'linear-gradient(45deg, #FFE6B2 0%, #FFF6E5 100%)'};
    border-radius: 16px;
    ${Media.lessThan(Media.small)} {
      border-radius: 8px;
    }
  `
}

export const PicRechargeItem = styled.div`
  margin-bottom: 30px;
  margin-right: 23px;
  padding: 5px;
  ${p => p.active && picRechargeItemActive(p.hoverSrc, p.disableHover)}
  :hover {
    ${p => picRechargeItemActive(p.hoverSrc, p.disableHover)}
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: 15px;
    margin-right: 0;
    padding: ${p => (p.hoverSrc ? '3px 3px 0' : '3px')};
    ${p => p.active && picRechargeItemActive('', p.disableHover)};
    :hover {
      ${p => picRechargeItemActive('', p.disableHover)}
    }
  }
`

export const PicPrizeList = styled(PrizeList)`
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  ${Media.lessThan(Media.small)} {
    padding-top: 28px;
  }
`

export const PrizeItem = styled.div`
  width: ${p => p.width || '400px'};
  margin-bottom: ${spacing.large};
  text-align: center;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    width: 150px;
    height: 130px;
    font-size: ${typography.textThin};
    margin-bottom: 70px;
  }
`
export const PrizeContent = styled.p`
  margin-top: 26px;
  ${hideSMDown}
`
export const PrizeMbContent = styled.div`
  ${hideSMUp}
  ${Media.lessThan(Media.small)} {
    margin-top: 14px;
  }
`
export const PrizeImg = styled.img`
  ${Media.lessThan(Media.small)} {
    height: ${p => !p.isPicture && '130px'};
    width: ${p => p.isPicture && '300px'};
    content: ${p => p.mbUrl && `url(${p.mbUrl}) !important`};
  }
`
export const PrizeImgWrap = styled.div`
  width: 100%;
  height: 240px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 0;
  background: linear-gradient(45deg, #1b593a 0%, #0f3327 100%);
  ${Media.lessThan(Media.small)} {
    height: 130px;
    border-radius: 6px;
    border-width: 1px;
  }
`

export const RulesList = styled.div`
  color: #ccc;
  width: 100%;
  display: flex;
  font-size: 16px;
  line-height: 36px;
  color: #c8c8c8;
  text-align: left;
  p {
    min-width: 120px;
    color: #fee8c3;
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    padding-left: 0;
    font-size: 10px;
    line-height: 18px;
    p {
      min-width: 70px;
    }
  }
`
export const HighltText = styled.span`
  color: ${color.primary};
`
export const DescriptionList = styled.div`
  color: #cccccc;
  line-height: 36px;
  color: #c8c8c8;
  font-size: 16px;
  padding: 0 146px 0 100px;
  p {
    margin: ${spacing.small} 0;
    padding-left: 1em;
    text-indent: -1em;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.large};
    font-size: 10px;
    padding: 0 18px 40px;
    line-height: 18px;
    p {
      margin: 0;
    }
  }
`
export const PicDescriptionList = styled(DescriptionList)`
  margin: 80px auto 10px;
  padding: 60px 80px 50px;
  background: #0c1f18;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 1280px;
  text-align: left;
  background: linear-gradient(45deg, rgba(41, 204, 95, 0.1) 0%, rgba(31, 153, 112, 0.1) 100%);
  border-radius: 12px;
  .rules-title {
    font-size: 20px;
    color: #c8c8c8;
    padding-bottom: 14px;
  }
  .qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 6px;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
    margin-top: 0;
    background: transparent;
    padding: 0 20px 10px;
    padding-bottom: ${p => p.paddingBottom};
    .rules-title {
      font-size: 12px;
      padding-bottom: 12px;
    }
    .qrcode {
      margin-top: 32px;
      color: #ffffff;
      margin-bottom: 30px;
      img {
        margin-bottom: 10px;
        width: 126px;
      }
    }
  }
`
export const SecondPrizeContainer = styled.div`
  font-size: ${typography.h3};
  color: #ffffff;
  text-align: center;
  .content01 {
    margin-bottom: 30px;
    .strong {
      color: #ffffff;
      font-size: 40px;
      font-weight: bold;
    }
  }
  .content02 {
    margin-bottom: 16px;
  }
  .content03 {
    display: flex;
    margin-bottom: 60px;
    align-items: center;
    justify-content: center;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    padding-top: 30px;
    .content01 {
      margin-bottom: 22px;
      .strong {
        font-size: 20px;
      }
    }
    .content02 {
      margin-bottom: 10px;
    }
    .content03 {
      margin-bottom: 30px;
    }
  }
`

export const HighltTitleCoupon = styled(HighltTitle)`
  position: relative;
  font-size: 18px;
  margin-right: 30px;
  color: #ffe6b2;
  line-height: 1;
  font-weight: bold;
  background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ::before {
    content: '';
    position: absolute;
    background-image: ${`url(${CouponIcon})`};
    background-size: 20px 17px;
    background-repeat: no-repeat;
    height: 17px;
    width: 20px;
    left: 0;
    top: 1px;
  }
  span {
    padding: 0 0 0 24px;
  }
  ${Media.lessThan(Media.small)} {
    margin-right: 10px;
    font-size: 11px;
    span {
      padding-left: 15px;
    }
    ::before {
      background-size: 12px 10px;
      height: 10px;
      width: 12px;
    }
  }
`
export const FirstRechargeTip = styled.div`
  font-size: ${typography.h3};
  margin: 30px auto 50px;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    margin: 15px auto 30px;
    font-size: 13px;
  }
`
export const PicAIBanner = styled.img`
  margin: 80px auto 0;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    content: ${`url(${aiMbImg})`};
    margin-top: 30px;
  }
`
export const GoCloundWarnTipMask = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  transition: transform 0.3s ease-in-out;
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
  }
`

export const GoCloundWarnBtnGroup = styled.div`
  display: flex;
  justify-content: space-around;
  ${Media.lessThan(Media.small)} {
    justify-content: space-between;
  }
`

export const GoCloundWarnTipDialogBody = styled.div`
  padding: 80px 62px 100px;
  text-align: center;
  .goclound-content {
    margin: 30px 0;
  }
  ${Media.lessThan(Media.small)} {
    padding: 40px 26px 53px;
    .goclound-content {
      margin-bottom: 20px;
    }
  }
`

export const GoCloundWarnTipDialog = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 34%;
  flex-direction: column;
  background: linear-gradient(20deg, #245737 0%, #222222 100%);
  box-shadow: 0px 10px 18px 2px rgba(255, 255, 255, 0.1);
  opacity: 0.9;
  border-radius: 10px;
  border: 0;
  ${Media.lessThan(Media.small)} {
    width: calc(100% - 40px);
    top: 27%;
    margin: 0 20px;
    left: 0;
    right: 0;
  }
`
export const GoCloundWarnHeader = styled.div`
  position: relative;
  width: 100%;
  &:after,
  :before {
    content: ' ';
    position: absolute;
    top: 30px;
    right: 30px;
    background: #999999;
    width: 16px;
    height: 1px;
    cursor: pointer;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
export const GoCloundWarnBtn = styled(Button)`
  border: 1px solid #13cb75;
  border-radius: 20px;
  padding: 4px 34px;
  font-weight: 400;
  color: #13cb75;
  line-height: 32px;
  font-size: 16px;
  p {
    font-size: 24px;
    color: #ffffff;
    line-height: 36px;
    margin: 50px auto 30px;
  }
  &:hover {
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    padding: 4px 26px;
    line-height: 24px;
    p {
      font-size: 16px;
      line-height: 27px;
      margin: 30px auto 20px;
    }
  }
  @media (max-width: 350px) {
    padding: 4px 12px;
  }
`

export const GoCloundWarnTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #13cb75;
  line-height: 36px;
  ${Media.lessThan(Media.small)} {
    font-size: 21px;
    line-height: 27px;
  }
`
