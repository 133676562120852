import React, { useState, useEffect } from 'react'
import { Wrapper, SEO } from '@renderbus/common/components'
import { baseLink, isTest, UserAuthorizationService, EventBus } from '@renderbus/common/service'
import {
  ContentContainer,
  TitleContent,
  PrizeList,
  PrizeItem,
  PrizeImgWrap,
  RulesList,
  DescriptionList,
  StepImgWrap,
  LotteryContent,
  LotterySubTitle,
  JoinActivityButton,
  YearEndRechargeBg,
  PrizeImg,
  PrizeContent,
  PrizeMbContent,
  HighltText,
  LotteryContentContainer,
  PicTitleContent,
  HighltTitle,
  RightsDetail,
  PicPrizeList,
  PicWrapper,
  RechargeInputContainer,
  RechargeInput,
  RechargeButton,
  RechargeTips,
  PicDescriptionList,
  SecondPrizeContainer,
  HighltTitleCoupon,
  FirstRechargeTip,
  PicAIBanner,
  PicRechargeItem,
  StepPicImgWrap,
  RechargePrefix,
  PicRechargeReceiptTip,
  RechargeErrorTip,
  GoCloundWarnTipMask,
  GoCloundWarnTipDialog,
  GoCloundWarnHeader,
  GoCloundWarnTipDialogBody,
  GoCloundWarnTitle,
  GoCloundWarnBtn,
  GoCloundWarnBtnGroup,
} from './ysdhnzhd.atom'
import { YearEndRecharge2023Banner } from '../molecules/banner'
import { API, Axios } from '@renderbus/common/utils'

import PrizeImage1 from '../images/year-end-recharge/2023/prize/prize-1.png'
import PrizeImage2 from '../images/year-end-recharge/2023/prize/prize-2.png'
import PrizeImage3 from '../images/year-end-recharge/2023/prize/prize-3.png'
import PrizeImage4 from '../images/year-end-recharge/2023/prize/prize-4.png'
import PrizeImage5 from '../images/year-end-recharge/2023/prize/prize-5.png'
import PrizeImage6 from '../images/year-end-recharge/2023/prize/prize-6.png'
import PrizeImage7 from '../images/year-end-recharge/2023/prize/prize-7.png'
import PrizeImage8 from '../images/year-end-recharge/2023/prize/prize-8.png'
import PrizeImageMb8 from '../images/year-end-recharge/2023/prize/prize-8-mb.png'
import CashPrize from '../images/year-end-recharge/2023/cash-prize.png'
import MorePrize from '../images/year-end-recharge/2023/more-prize.png'
import MyPrizeCode from '../images/year-end-recharge/2023/my-prize-code.png'
import CashPrizeHover from '../images/year-end-recharge/2023/cash-prize-hover.png'
import MorePrizeHover from '../images/year-end-recharge/2023/more-prize-hover.png'
import MyPrizeCodeHover from '../images/year-end-recharge/2023/my-prize-code-hover.png'

import stepPC from '../images/year-end-recharge/2023/activity-rule-step.png'
import stepMobile from '../images/year-end-recharge/2023/activity-rule-step-mb.png'
import Layout from '../molecules/layout'
import PointerIcon from '../images/year-end-recharge/2023/pointer.png'
import QrcodeIcon from '../images/year-end-recharge/2023/qrcode.png'
import stepPicPC from '../images/year-end-recharge/2023/flowChart.png'
import stepPicPCMobile from '../images/year-end-recharge/2023/flowChart-mb.png'

import recharge01 from '../images/year-end-recharge/2023/recharge01.png'
import recharge02 from '../images/year-end-recharge/2023/recharge02.png'
import recharge03 from '../images/year-end-recharge/2023/recharge03.png'
import recharge04 from '../images/year-end-recharge/2023/recharge04.png'
import recharge05 from '../images/year-end-recharge/2023/recharge05.png'
import recharge04Hover from '../images/year-end-recharge/2023/recharge04-hover.png'
import recharge05Hover from '../images/year-end-recharge/2023/recharge05-hover.png'
import recharge06 from '../images/year-end-recharge/2023/recharge06.png'
import recharge07 from '../images/year-end-recharge/2023/recharge07.png'
import recharge08 from '../images/year-end-recharge/2023/recharge08.png'

import recharge01Mb from '../images/year-end-recharge/2023/recharge01-mb.png'
import recharge02Mb from '../images/year-end-recharge/2023/recharge02-mb.png'
import recharge03Mb from '../images/year-end-recharge/2023/recharge03-mb.png'
import recharge04Mb from '../images/year-end-recharge/2023/recharge04-mb.png'
import recharge05Mb from '../images/year-end-recharge/2023/recharge05-mb.png'
import recharge06Mb from '../images/year-end-recharge/2023/recharge06-mb.png'
import recharge07Mb from '../images/year-end-recharge/2023/recharge07-mb.png'
import recharge08Mb from '../images/year-end-recharge/2023/recharge08-mb.png'
import aiImg from '../images/year-end-recharge/2023/ai.png'

const prizeImgList = [
  {
    name: '英伟达 RTX ',
    subname: 'A4000显卡 ×1',
    src: PrizeImage1,
  },
  {
    name: 'iPhone 15 ',
    subname: '（128GB）×2',
    src: PrizeImage2,
  },
  {
    name: '大疆DJI Mini3 ',
    subname: '航拍无人机 ×3',
    src: PrizeImage3,
  },
  {
    name: 'Spyder X2 Ultra ',
    subname: '红蜘蛛校色仪 ×4',
    src: PrizeImage4,
  },
  {
    name: '雷蛇 黑寡妇蜘蛛V3酷黑',
    subname: '(线性黄轴) ×5',
    src: PrizeImage5,
  },
  {
    name: '倍轻松 Breo-C2',
    subname: '颈部热敷按摩器 ×20',
    src: PrizeImage6,
  },
  {
    name: '罗技 M575无线轨迹球 ',
    subname: '鼠标 ×30',
    src: PrizeImage7,
  },
  {
    name: 'Renderbus定制 ',
    subname: '鼠标垫数据线/天堂伞/U型枕',
    subname1: '(参与可得，三套周边随机赠送)',
    src: PrizeImage8,
    srcMb: PrizeImageMb8,
    width: '840px',
  },
]

const picRechargeDetailList = [
  { url: recharge01, mbUrl: recharge01Mb, num: 20 },
  { url: recharge02, mbUrl: recharge02Mb, num: 50 },
  { url: recharge03, mbUrl: recharge03Mb, num: 200 },
  { url: recharge04, mbUrl: recharge04Mb, num: 1000, vipMonth: 1, hoverUrl: recharge04Hover },
  { url: recharge05, mbUrl: recharge05Mb, num: 5000, vipMonth: 6, hoverUrl: recharge05Hover },
]
const picFirstRechargeDetailList = [
  { url: recharge06, mbUrl: recharge06Mb },
  { url: recharge07, mbUrl: recharge07Mb },
  { url: recharge08, mbUrl: recharge08Mb },
]

const picMemberRightsList = [
  {
    content: '尊享渲染',
    hightContent: '单价75折',
  },
  {
    content: '每月免单',
    hightContent: '4张图',
  },
  {
    content: '单张渲染',
    hightContent: '20元封顶',
  },
]

const picAuthPrizeList = ['5元好友邀请渲染券', '20元无门槛渲染券', '25元满赠券']
const MidYearRecharge2023 = React.memo(function(props) {
  const {
    location,
    pageContext: { topThreeShare },
  } = props
  const [isPictureType, setIsPictureType] = useState(false)
  const [isOpenRechargeTip, setIsOpenRechargeTip] = useState(false)
  const [isPictureUser, setIsPictureUser] = useState(false)
  const [currentAccount, setCurrentAccount] = useState('动画')
  const [currentRechargeLink, setCurrentRechargeLink] = useState('')
  const [currentRechargeCard, setCurrentRechargeCard] = useState(1000)
  const [isClickRerchageBtn, setIsClickRerchageBtn] = useState(false)
  const [isDisableRecharge, setIsDisableRecharge] = useState(
    currentRechargeCard < 5 || currentRechargeCard >= 20000000,
  )

  const [currentRechargeTip, setCurrentRechargeTip] = useState(
    '余额到账 ¥1000｜¥200 通用券｜赠送效果图1个月会员',
  )
  const handleClickCard = ({ num, vipMonth }) => {
    setCurrentRechargeCard(num)
    setCurrentRechargeTip(
      `余额到账 ¥${num}｜¥${Math.ceil(num * 0.2)} 通用券 ${(!!vipMonth &&
        `｜赠送效果图${vipMonth}个月会员`) ||
        ''}`,
    )
    setIsDisableRecharge(num < 5 || num >= 200000)
  }
  const handleCalcRecharge = num => {
    setCurrentRechargeCard(num)
    setIsDisableRecharge(num < 5 || num >= 200000)
    let vipMonth = 0
    if (num >= 1000 && num < 5000) vipMonth = 1
    else if (num >= 5000) vipMonth = 6
    setCurrentRechargeTip(
      `余额到账 ¥${num || 0}｜¥${Math.ceil(num * 0.2)} 通用券 ${(!!vipMonth &&
        `｜赠送效果图${vipMonth}个月会员`) ||
        ''}`,
    )
  }

  const handleRecharge = url => {
    if (!UserAuthorizationService.isLogined()) {
      setIsClickRerchageBtn(true)
    } else {
      const isPicture = UserAuthorizationService.getoginUserType() === 'true'
      setIsPictureUser(isPicture)
      setCurrentAccount(isPicture ? '效果图' : '动画')
      const htmlElement = document.documentElement
      htmlElement.style.overflow = 'hidden'
      htmlElement.style['-webkit-overflow-scrolling'] = 'touch'
      htmlElement.style.width = '100%'
      const isDiffType = (isPictureType && !isPicture) || (!isPictureType && isPicture)
      setIsOpenRechargeTip(isDiffType)
      setCurrentRechargeLink(url)
      if (isDiffType) return
    }
    window.location.href = url
  }

  const getMorePrizeCode = async () => {
    const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
    const hasActivityQualification = await instance.post(API.event.getSupportActivity)
    if (hasActivityQualification && [602, 603].includes(hasActivityQualification.code)) {
      handleRecharge(`${baseLink()}/center/user/topUp`)
      return
    }
    if (!(hasActivityQualification.data && hasActivityQualification.data.length)) return
    const { code, data } = await instance.post(API.event.getUserPrizeCode, {
      activityId: hasActivityQualification.data[0].id,
    })

    if (data && code === 200) {
      if (!data.length) handleRecharge(`${baseLink()}/center/user/topUp`)
      if (data && data.filter(item => item.payRecordId).length) {
        handleRecharge(
          `${
            isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
          }/nvidia-form.html?activityId=${hasActivityQualification.data[0].id}`,
        )
      }
    }
  }

  const handleOpenRechargeTip = () => {
    if (document.hidden) return
    if (isClickRerchageBtn && isOpenRechargeTip) {
      handleRecharge()
    }
  }
  const handleCloseDialog = () => {
    const htmlElement = document.documentElement
    const viewportWidth = document.body.clientWidth
    htmlElement.style.overflowY = 'auto'
    htmlElement.style.overflowX = 'auto'
    htmlElement.style['-webkit-overflow-scrolling'] = 'unset'
    if (viewportWidth < 780) {
      htmlElement.style.overflowX = 'hidden'
    }
    setIsOpenRechargeTip(false)
    setIsClickRerchageBtn(false)
  }
  useEffect(() => {
    const isPicture = UserAuthorizationService.getoginUserType() === 'true'
    setIsPictureUser(isPicture)
    setCurrentAccount(isPicture ? '效果图' : '动画')
    if (!location.search) return
    setIsPictureType(location.search.includes('type=picture'))
  }, [location.search])

  useEffect(() => {
    if (typeof window === 'undefined') return
    EventBus.addListener('loginStatusUpdate', handleOpenRechargeTip)
    return () => {
      EventBus.removeListener('loginStatusUpdate', handleOpenRechargeTip)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout location={location} topThreeShare={topThreeShare}>
      <SEO
        title='瑞云渲染年终狂欢充值享壕礼活动 - Renderbus瑞云渲染'
        keywords='影视动画充值活动,影视动画抽奖活动,瑞云渲染充值活动'
        description='Renderbus瑞云渲染年终云渲染用户专享大促活动！渲染农场年终充值享壕礼、多重福利送不停，参与活动即有机会抽取NVIDIA RTX A4000专业显卡、iPhone15等多重壕礼！'
      />
      <YearEndRecharge2023Banner
        isPictureType={isPictureType}
        handleChangeType={value => setIsPictureType(value)}
      />
      {isPictureType ? (
        <YearEndRechargeBg isPicture={true}>
          <PicWrapper>
            <PicTitleContent data-title='一重礼' marginTop='0' pcWidth='960px'>
              <div class='pic-title-detail'>
                效果图充值加赠<HighltTitle>20%渲染券！满一千再送会员！</HighltTitle>
              </div>
            </PicTitleContent>
            <ContentContainer>
              <PicPrizeList>
                {picRechargeDetailList.map((item, index) => {
                  return (
                    <PicRechargeItem
                      key={index}
                      hoverSrc={item.hoverUrl}
                      onClick={() => handleClickCard(item)}
                      onMouseEnter={() => handleClickCard(item)}
                      active={item.num === currentRechargeCard}
                    >
                      <PrizeImg src={item.url} alt={index} mbUrl={item.mbUrl} isPicture={true} />
                    </PicRechargeItem>
                  )
                })}
              </PicPrizeList>
              {[1000, 5000].includes(currentRechargeCard) && (
                <RightsDetail>
                  <div class='rights-title'>*会员权益如下</div>
                  <div class='right-content'>
                    {picMemberRightsList.map((item, index) => {
                      return (
                        <div class='member-content' key={index}>
                          {item.content}
                          <span class='highlight'>{item.hightContent}</span>
                        </div>
                      )
                    })}
                  </div>
                </RightsDetail>
              )}

              <RechargeInputContainer>
                充值金额
                <RechargePrefix>￥</RechargePrefix>
                <RechargeInput
                  type='number'
                  value={currentRechargeCard}
                  onChange={e => handleCalcRecharge(e.target.value)}
                />
                <RechargeButton
                  id='nzjh-ljcz'
                  as='a'
                  isDisableRecharge={isDisableRecharge}
                  onClick={() => handleRecharge(`${baseLink()}/center/user/topUp`)}
                >
                  立即充值
                </RechargeButton>
                <PicRechargeReceiptTip>到账120% </PicRechargeReceiptTip>
              </RechargeInputContainer>
              {isDisableRecharge && (
                <RechargeErrorTip>请输入5.00-200,000.00之间的数值</RechargeErrorTip>
              )}
              <RechargeTips>{currentRechargeTip}</RechargeTips>
              <PicDescriptionList>
                <div>
                  <div class='rules-title'>活动规则:</div>
                  <p>1. 活动仅限Renderbus瑞云渲染效果图用户参与 </p>
                  <p>2. 活动期间充值金额≥5元实际到账120%，其中20%为赠送通用渲染券，有效期1个月</p>
                  <p>
                    3.活动期间充值次数不限，仅1000元和5000元两个档位参与充值送会员，多次充值则会员期限相应叠加
                  </p>
                  <p>4.活动期间首充福利和年终充值福利可以同时参与，到账渲染券为叠加福利</p>
                  <p>5. 赠送的渲染券为即时到账，请及时查看账户余额，有问题联系客服</p>
                  <p>6. 活动赠送的通用券及会员不可申请发票、不可提现</p>
                  <p>*本活动最终解释权归深圳市瑞云科技股份有限公司所有</p>
                </div>
                <div class='qrcode'>
                  <img src={QrcodeIcon} alt='扫码领额外福利' />
                  <span>扫码领额外福利</span>
                </div>
              </PicDescriptionList>
            </ContentContainer>
            <PicTitleContent data-title='二重礼' pcWidth='630px' mbLeft='16px'>
              <div class='pic-title-detail'>
                邀请好友，双方<HighltTitle>免费渲染！</HighltTitle>
              </div>
            </PicTitleContent>
            <ContentContainer>
              <SecondPrizeContainer>
                <div class='content01'>
                  <span>邀请好友立得</span>
                  <HighltTitle fontSize='30px' mbFontSize='15px'>
                    &nbsp;5元&nbsp;
                  </HighltTitle>
                  <span>无门槛渲染券！</span>
                  <span class='strong'>上不封顶！</span>
                </div>
                <div class='content02'>
                  <span>被邀请好友注册实名后可获得价值</span>
                  <HighltTitle fontSize='30px' mbFontSize='15px'>
                    &nbsp;50元&nbsp;
                  </HighltTitle>
                  <span>的新手大礼包！</span>
                </div>
                <div class='content03'>
                  {picAuthPrizeList.map((item, index) => {
                    return (
                      <HighltTitleCoupon key={index}>
                        <span>{item}</span>
                      </HighltTitleCoupon>
                    )
                  })}
                </div>
              </SecondPrizeContainer>
              <StepPicImgWrap>
                <img src={stepPicPC} alt='邀请好友' />
                <img src={stepPicPCMobile} alt='邀请好友' />
                <JoinActivityButton
                  width='300px'
                  mbWidth='180px'
                  as='a'
                  href={`${baseLink()}`}
                  target='_blank'
                >
                  邀请好友
                </JoinActivityButton>
                <PicDescriptionList>
                  <div>
                    <div class='rules-title'>活动规则:</div>
                    <p>1.好友邀请仅限注册Renderbus渲染图效果图用户 </p>
                    <p>2.好友需通过效果图账户复制的专属链接注册，并完成实名认证</p>
                    <p>3.好友邀请获得的渲染券有效期1个月</p>
                    <p>
                      4.25元满赠券需在新用户注册后的15天内使用，单笔充值满100元自动加赠25元通用渲染券，该券不适用于充值会员
                    </p>
                    <p>5.赠送的渲染券为即时到账，请及时查看账户余额，有问题联系客服</p>
                    <p>6.活动赠送的通用券及会员不可申请发票、不可提现</p>
                    <p>*本活动最终解释权归深圳市瑞云科技股份有限公司所有</p>
                  </div>
                </PicDescriptionList>
              </StepPicImgWrap>
            </ContentContainer>
            <PicTitleContent data-title='三重礼' pcWidth='960px' mbLeft='10px'>
              <div class='pic-title-detail'>
                新老用户
                <HighltTitle>首充享特惠</HighltTitle>
                <span class='pic-title-detail02'>，</span>
                <div>
                  与「一、二重礼」
                  <HighltTitle>可叠加！</HighltTitle>
                </div>
              </div>
            </PicTitleContent>
            <ContentContainer>
              <PicPrizeList>
                {picFirstRechargeDetailList.map((item, index) => {
                  return (
                    <PicRechargeItem key={index} disableHover={true}>
                      <PrizeImg src={item.url} alt={index} mbUrl={item.mbUrl} isPicture={true} />
                    </PicRechargeItem>
                  )
                })}
              </PicPrizeList>
              <FirstRechargeTip>
                快前往充值中心输入充值金额，享受层层叠加的优惠吧！
              </FirstRechargeTip>
              <JoinActivityButton
                width='300px'
                mbWidth='180px'
                id='nzjh-djck'
                as='a'
                onClick={() => handleRecharge(`${baseLink()}/center/user/topUp`)}
              >
                立即充值
              </JoinActivityButton>
              <PicDescriptionList paddingBottom='40px'>
                <div>
                  <div class='rules-title'>活动规则:</div>
                  <p>1.首充优惠仅限首次在平台充值的效果图用户参与 </p>
                  <p>2.首充优惠三选一，仅限一次。赠券系统自动充值到账</p>
                  <p>*本活动最终解释权归深圳市瑞云科技股份有限公司所有</p>
                </div>
              </PicDescriptionList>
            </ContentContainer>
            <PicAIBanner src={aiImg} alt='华为AI' />
          </PicWrapper>
        </YearEndRechargeBg>
      ) : (
        <YearEndRechargeBg>
          <Wrapper>
            <TitleContent mdPaddingTop='10px'>奖品详情</TitleContent>
            <ContentContainer>
              <PrizeList>
                {prizeImgList.map((item, index) => {
                  return (
                    <PrizeItem key={index} width={item.width}>
                      <PrizeImgWrap>
                        <PrizeImg src={item.src} alt={item.name} mbUrl={item.srcMb} />
                      </PrizeImgWrap>
                      <PrizeContent>{`${item.name}${item.subname}${item.subname1 ??
                        ''}`}</PrizeContent>
                      <PrizeMbContent>
                        {item.name}
                        <br />
                        {item.subname}
                        <br />
                        {item.subname1}
                      </PrizeMbContent>
                    </PrizeItem>
                  )
                })}
              </PrizeList>
              <JoinActivityButton
                as='a'
                id='ysdhnzhd-ljcz'
                onClick={() => handleRecharge(`${baseLink()}/center/user/topUp`)}
              >
                立即参与
              </JoinActivityButton>
            </ContentContainer>
            <TitleContent>活动规则</TitleContent>
            <ContentContainer>
              <StepImgWrap>
                <img src={stepPC} alt='活动规则' />
                <img src={stepMobile} alt='活动规则' />
                <RulesList>
                  <p>【 参与方式 】</p>
                  <div>
                    活动期间，影视动画用户单笔充值
                    <HighltText>每满5000元</HighltText>
                    ，即可获得1张抽奖券，获得奖券后再
                    <HighltText>填写问卷</HighltText>
                    即可额外多获得1张抽奖券，每个用户通过充值最多可获得
                    <HighltText>30张</HighltText>抽奖券
                  </div>
                </RulesList>
                <RulesList>
                  <p>【 活动时间 】</p>
                  <span>2023年11月8日10:00～2023年12月29日12:00，12月29日晚20:00统一直播抽奖</span>
                </RulesList>
                <RulesList>
                  <p>【 活动范围 】</p>
                  <span>Renderbus影视动画用户</span>
                </RulesList>
                <RulesList>
                  <p>【 开奖时间 】</p>
                  <span>2023年12月29日20:00，B站直播间（@Renderbus瑞云渲染 ）统一直播开奖</span>
                </RulesList>
                <RulesList>
                  <p>【 兑奖方式 】</p>
                  <span>
                    用户中奖后，请在瑞云渲染官网活动页面-兑奖专区-立即兑奖，或者点击官网充值页面导航栏
                    <HighltText>【充值抽豪礼】</HighltText>
                    图标处，查看奖券并填写相关兑奖信息进行兑奖
                  </span>
                </RulesList>
                <RulesList>
                  <p>【 兑奖时间 】</p>
                  <span>2024年1月2日10:00开放兑奖链接</span>
                </RulesList>
                <RulesList>
                  <p>【 兑奖截止时间 】</p>
                  <span>2024年1月15日18:00</span>
                </RulesList>
              </StepImgWrap>
            </ContentContainer>
            <TitleContent>活动说明</TitleContent>
            <ContentContainer>
              <DescriptionList>
                <p>1. 如对本活动有任何问题，可以微信添加瑞云小助手（微信号：rayvision1）进行咨询</p>
                <p>2. 青云平台用户不参与本次活动，若动画用户企业账号参与活动，则以主账号为准</p>
                <p>
                  3.
                  每个用户通过充值最多可获得30张抽奖券；没有中奖的客户可凭一张券兑换一套周边，多券多换
                </p>
                <p>
                  4.
                  已中1-5等奖的用户和同时中2个及以上奖的用户不可再兑参与奖，仅中6-7等奖的用户可单独找各自业务经理申请兑换剩余奖券的参与奖（定制鼠标垫数据线/定制天堂晴雨伞/定制U型枕三套周边随机赠送），6等奖封顶10套，7等奖封顶20套
                </p>
                <p>
                  5. 已充值5000元以上的用户，登录后可点击导航栏
                  <HighltText>【充值抽豪礼】</HighltText>
                  图标，查看已获得的抽奖券和问卷，开奖后也可以到该页面填写兑奖信息
                </p>
                <p>6. 只有已经获得抽奖券的用户才能填写问卷，获得更多奖券码</p>
                <p>7.抽奖奖品一律不支持折换现金，未在截止日期前兑换奖品的用户视为放弃奖品</p>
                <p>
                  8.本次活动最终解释权归深圳市瑞云科技股份有限公司所有，瑞云有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！
                </p>
              </DescriptionList>
            </ContentContainer>
            <TitleContent iconSpacing={'37%'} iconMbSpacing={'24%'}>
              我的瑞云抽奖
            </TitleContent>
            <LotteryContentContainer>
              <LotteryContent
                as='a'
                onClick={() => getMorePrizeCode()}
                target='_blank'
                id='ysdhnzhd-txwj'
                hoverUrlSrc={MorePrizeHover}
                urlSrc={MorePrize}
              >
                <div class='lottery-img' />
                <div class='lottery-title'>获取更多奖券</div>
                <LotterySubTitle>填写调查问卷</LotterySubTitle>
                <LotterySubTitle>可额外获一张抽奖券</LotterySubTitle>
              </LotteryContent>
              <LotteryContent
                as='a'
                onClick={() => handleRecharge(`${baseLink()}/center/user/topUp?openCashPrize=true`)}
                target='_blank'
                id='ysdhnzhd-djck'
                urlSrc={MyPrizeCode}
                hoverUrlSrc={MyPrizeCodeHover}
              >
                <div class='lottery-img' />
                <div class='lottery-title'>我的奖券码</div>
                <LotterySubTitle>点击查看</LotterySubTitle>
                <LotterySubTitle>已获得奖券码及中奖情况</LotterySubTitle>
              </LotteryContent>
              <LotteryContent
                as='a'
                onClick={() => handleRecharge(`${baseLink()}/center/user/topUp?openCashPrize=true`)}
                target='_blank'
                id='ysdhnzhd-djdhjp'
                urlSrc={CashPrize}
                hoverUrlSrc={CashPrizeHover}
              >
                <div class='lottery-img' />
                <div class='lottery-title'>兑奖专区</div>
                <LotterySubTitle>点击兑换奖品</LotterySubTitle>
              </LotteryContent>
            </LotteryContentContainer>
            <JoinActivityButton
              as='a'
              onClick={() => handleRecharge(`${baseLink()}/center/user/topUp`)}
              target='_blank'
              id='ysdhnzhd-ljcyhd'
              marginTop='60px'
            >
              立即参与活动，抽取壕华礼品！
              <img src={PointerIcon} alt='pointer' />
            </JoinActivityButton>
          </Wrapper>
        </YearEndRechargeBg>
      )}
      {isOpenRechargeTip && (
        <GoCloundWarnTipMask>
          <GoCloundWarnTipDialog>
            <GoCloundWarnHeader onClick={() => handleCloseDialog()} />
            <GoCloundWarnTipDialogBody>
              <GoCloundWarnTitle>温馨提示</GoCloundWarnTitle>
              <p className='goclound-content'>
                您当前为{currentAccount}登陆状态，即将前往{currentAccount}充值页
              </p>
              <GoCloundWarnBtnGroup>
                <GoCloundWarnBtn
                  as='a'
                  href={`${baseLink()}/sso`}
                  onClick={() => handleCloseDialog()}
                >
                  {`切换${isPictureUser ? '动画' : '效果图'}账号`}
                </GoCloundWarnBtn>
                <GoCloundWarnBtn
                  as='a'
                  href={`${currentRechargeLink}`}
                  onClick={() => handleCloseDialog()}
                >
                  {`继续${currentAccount}充值`}
                </GoCloundWarnBtn>
              </GoCloundWarnBtnGroup>
            </GoCloundWarnTipDialogBody>
          </GoCloundWarnTipDialog>
        </GoCloundWarnTipMask>
      )}
    </Layout>
  )
})

export default MidYearRecharge2023
